import { Show, For, Component, createResource, createEffect } from "solid-js";
import { createStore } from "solid-js/store";
import MapGL, { Marker, Control } from "solid-map-gl";
import Body from "~/components/Body";
import { MetaDetails } from "~/components/utility";
import { getBuilderContentByModel } from "~/utils/builder";

import "mapbox-gl/dist/mapbox-gl.css";

const Locations = () => {
  const [locations] = createResource<LocationModel[]>(async () => {
    const locations = (await getBuilderContentByModel("locations", 999, 0)) as {
      name: string;
      data: Omit<LocationModel, "name">;
    }[];
    return locations?.map((location) => {
      return {
        name: location.name,
        ...location.data,
      };
    });
  });

  const [viewPort, setViewPort] = createStore({
    center: [-96.5831478, 39.3863534],
    zoom: 3.3,
  });

  const MapPin: Component<{ index: number }> = (props) => {
    return (
      <div class="pb-10">
        <div class="w-10 h-10 bg-roma-blue rounded-full flex justify-center items-center border-2 border-white rotate-45 rounded-br-none">
          <p class="text-white font-bold -rotate-45">{props.index}</p>
        </div>
      </div>
    );
  };

  return (
    <Show when={locations()}>
      <MetaDetails
        title="Roma Moulding Locations"
        description="Roma HQ and warehouse locations"
        keywords="Location,Warehouse"
      />
      <Body>
        <div class="flex flex-col-reverse md:flex-row mt-8 h-full md:max-h-[80vh] overflow-hidden">
          <section class="basis-1/3  max-md:mt-8  overflow-y-auto px-3 relative">
            <div class="border-b pb-2 md:pr-6 sticky top-0 backdrop-blur-md bg-white/80">
              <h2 class="text-2xl font-bold mb-4">Roma Moulding Locations</h2>
              <p class=" text-roma-medium-grey text-sm">
                {locations()!.length} locations near you
              </p>
            </div>
            <div class="child:pr-2">
              <For each={locations()}>
                {(location, index) => (
                  <div
                    class="flex items-center py-6 hover:bg-roma-grey duration transition-200 border-b cursor-pointer pl-2"
                    onClick={() => {
                      setViewPort({
                        center: [location.longitude, location.latitude],
                        zoom: 5.5,
                      });
                    }}
                  >
                    <div class="basis-[35px] self-start shrink-0 grow-0 text-roma-blue ml-2">
                      {index() + 1}
                    </div>
                    <div class="grow shrink-0 flex flex-col">
                      <div class="text-lg md:col-span-3">{location.name}</div>
                      <div class="text-sm">
                        <p>{location.address}</p>
                        <p>
                          {location.city}, {location.region}{" "}
                          {location.postalCode}, {location.country}
                        </p>
                      </div>
                      <a
                        target="_blank"
                        href={`https://www.google.com/maps/dir/?api=1&destination=${location.address.replaceAll(
                          " ",
                          "+"
                        )},+${location.city},+${location.region},+${
                          location.country
                        }`}
                        class="self-start grow-0  mt-2 rounded-full border hover:text-white transition-colors duration-100 hover:bg-black border-black px-2 py-2 text-xs whitespace-nowrap"
                      >
                        Get Directions
                      </a>
                    </div>
                  </div>
                )}
              </For>
            </div>
          </section>
          <section class="basis-2/3">
            <MapGL
              class="h-[35vh] md:h-[80vh]"
              viewport={{ ...viewPort }}
              options={{
                projection: "mercator",
                dragPan: true,
                doubleClickZoom: true,
                interactive: true,
                accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
                style: "mb:light",
                scrollZoom: false,
              }}
            >
              <Control type="navigation" position="top-right" />
              <For each={locations()}>
                {(location, index) => (
                  <Marker
                    lngLat={[location.longitude, location.latitude]}
                    popup={{ focusAfterOpen: true, closeOnClick: true }}
                    options={{
                      color: "#0079d1",
                      element: (<MapPin index={index() + 1} />) as HTMLElement,
                    }}
                  >
                    {`<div class="p-2"><p class="font-semibold text-lg mb-2 border-b border-roma-gray min-w-[200px]">
                    <span class="font-bold text-roma-blue mr-2 ">${
                      index() + 1
                    }</span>
                    ${location.name}
                  </p>
                  <p>${location.address}</p>
                  <p>
                    ${location.region}, ${location.country}
                  </p>
                  <p>${location.postalCode}</p></div>`}
                  </Marker>
                )}
              </For>
            </MapGL>
          </section>
        </div>
      </Body>
    </Show>
  );
};

export default Locations;

type LocationModel = {
  name: string;
  address: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  postalCode: string;
  region: string;
};
